import Vue from 'vue'

import config from '../../config'
import ExperienceService from '../../services/experience';
import GlobalService from '../../services/global';

//custom components
import CstmCountryPicker from '../../components/cstm-country-picker/cstm-country-picker.vue';
import Loading from '../../components/loading/loading.vue';

export default {
  name: 'NewExperienceInfo',

  components: {
    CstmCountryPicker,
    Loading
  },

  computed: {
    modalPhotos() {
      return this.experience.photos.filter(x => x);
    }
  },

  methods: {
    form1() {
      if (!this.$refs.form1.validate()) return;
      if (!this.categories.filter(x => x.selected).length) return this.catsErr();
      this.proceed(2);
    },

    form2() {
      if (!this.$refs.form2.validate()) return;
      const cats = [];
      this.categories.forEach(x => { if (x.selected) cats.push(x.name); });
      this.experience.categories = cats;
      this.proceed(3);
    },

    form3() {
      if (!this.$refs.form3.validate()) return;
      this.proceed(4);
    },

    catsErr() {
      this.le = true;
      setTimeout(() => { this.le = false; }, 2500);
    },

    getPhotoURL(data) {
      return GlobalService.getExpPhoto(data);
    },

    getRef(index) {
      return this.$refs['inputUpload'+index][0];
    },

    handleClick(index, photo) {
      if (!photo.id) this.pickImage(index);
      else photo.sheet = !photo.sheet;
    },

    setAsDp(index, photo) {
      photo.sheet = false;
      this.photos.splice(index, 1);
      this.photos.splice(0, 0, photo);

      this.experience.photos.splice(index, 1);
      this.experience.photos.splice(0, 0, photo.id);
      this.proceed();
    },

    removeImage(index, photo) {
      this.photos.splice(index, 1);
      this.photos.push(this.photoItem);

      //and actual
      this.experience.photos.splice(index, 1);

      photo.sheet = false;
      this.proceed();
    },

    pickImage(index) {
      this.getRef(index).click();
    },

    pushPhotoObject(index, id, uploading, uploadProgress, sheet) {
      Vue.set(this.photos, index, {uploading: uploading, id: id, uploadProgress: uploadProgress, sheet: sheet});
    },

    invalidImageError() {
      this.showError = true;
      setTimeout(() => { this.showError = false }, 2000);
    },

    isFileImage(file) {
      return file && file['type'].split('/')[0] === 'image';
    },

    fileSelected(index) {
      const file = this.getRef(index).files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();
      const localURL = URL.createObjectURL(file);
      this.pushPhotoObject(index, localURL, true, 0, false);
      this.upload(file, index, localURL);
    },

    upload(file, index, localURL) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      Vue.axios.defaults.headers.common['X-Request-Pattern'] = 'new'; //for backend ais
      
      return Vue.axios
        .post(`file/e`, formData, {
          onUploadProgress: progressEvent => {
            const uploadProgress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
            this.pushPhotoObject(index, localURL, true, uploadProgress, false);
          }
        })
        .then(res => {
          //removing the added header
          Vue.axios.defaults.headers.common['X-Request-Pattern'] = undefined;
          const uploadedPhoto = res.data._id;
          this.pushPhotoObject(index, uploadedPhoto, false, 0, false);
          const photos = this.photos.map(x => x.id);
          this.experience.photos = photos;
          this.proceed();
        })
        .catch(err => {
          console.log(err);
        })
    },

    proceed(nstep = 0) {
      let expObject = this.experience;

      this.saving = true;
      if (expObject._id) {
        return ExperienceService
          .updateExperience(expObject)
          .then(r => {
            this.experience = r.data;
            if (nstep) this.e6 = nstep;
            this.saving = false;
          })
          .catch(err => {
            console.log(err);
            this.saving = false;
          })
      }
      //create new
      return ExperienceService
        .createExperience(expObject)
        .then(r => {
          this.experience = r.data;
          if (nstep) this.e6 = nstep;
          this.saving = false;
        })
        .catch(err => {
          console.log(err);
          this.saving = false;
        })  
    },

    submit() {
      if (!this.experience.photos.filter(x => x).length) return;
      this.saving = true;
      return ExperienceService
        .changeStatus(this.experience._id, 'active')
        .then(() => {
          this.saving = false;
          window.scrollTo(0,0);
          this.submittedModal = true;
        })
        .catch(e => {
          this.saving = false;
          console.log(e);
        });
    },

    fetchExperience() {
      this.showLoadingModal = true;
      return ExperienceService
        .getExperience(this.experienceId)
        .then(response => {
          let experience = response.data;
          experience.uname = 'l';
          this.experience = ExperienceService.mapExperience(experience);
        })
        .catch(error => {
          console.log(error);
          this.showLoadingModal = false;
        }); 
    },
  },

  mounted() {
    this.loading = true;
    
    let promises = [];

    promises.push(
      ExperienceService
        .getEnums()
        .then(result => {
          const newCats = [];
          result.data.categories.forEach(item => {
            newCats.push({selected: false, name: item});
          })
          this.categories = newCats;
          this.duration = result.data.duration;
          this.channel = result.data.channel;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        })
    )
    if (this.experienceId) promises.push(this.fetchExperience());

    return Promise.all(promises)
      .then(() => {
        if (this.experienceId && this.$route.params.slug === 'modify') {
          //map categories
          this.categories.forEach(c => {
            if (this.experience.categories && this.experience.categories.includes(c.name)) c.selected = true;
          });

          //map photos
          this.experience.photos.forEach(item => {
            if (item) this.photos.push({uploading: false, id: item, uploadProgress: 0, sheet: false})
            else this.photos.push(this.photoItem);
          })

          //map country/currency
          const c = this.experience.pricing.currency;
          if (c && c.name) this.country = c;

          //hide the modal
          this.showLoadingModal = false;
        } 
        
        const totalEmpty = 8-this.photos.length;
        for (let p = 0; p < totalEmpty; p++) this.photos.push(this.photoItem);
      });
  },

  watch: {
    country(val) {
      if (val) {
        this.experience.pricing.currency = {
          name: val.name,
          symbol: val.symbol,
          code: val.code,
          country: val.country
        }
      }
    }
  },

  data() {
    return {
      loading: false, 
      submittedModal: false,

      showLoadingModal: false,
      experienceId: this.$route.params.id,

      e6: 1,

      valid1: false,
      valid2: false,
      valid3: false,

      le: false,

      placeholder: config.meta.placeholder,
      saving: false,
      categories: [],
      duration: [],
      channel: [],
      photos: [],
      lazy: require("@/assets/imgs/placeholder.png"),
      yesnoItems: [ {key: 'Yes', value: true}, {key: 'No', value: false}],
      country: {
        code: '',
        symbol: '',
        country: '',
        name: ''
      },

      experience: {
        title: '',
        channel: 'Zoom',
        channelId: '',
        leftAt: 'basic',
        duration: '1 hour',
        youWillDo: '',
        youWillTake: '',
        requirements: '',
        pricing: {
          isAvailableFree: true,
          amount: 0,
            currency: {
              code: '',
              symbol: '',
              country: '',
              name: ''
            }
        },
        categories: [],
        photos: [],
      },

      photoItem: {
        uploading: false, 
        id: undefined, 
        progress: 0, 
        sheet: false
      },

      validationRules: {
        basicRules: [
          v => !!v || 'It cannot be empty'
        ],
        priceRules: [
          v => /^\d+$/.test(v) || this.experience.pricing.isAvailableFree || 'Must be valid a valid price',
          v => !!v || this.experience.pricing.isAvailableFree || 'Must be valid a valid price',
          v => v != 0 || v != '0' || this.experience.pricing.isAvailableFree || 'Must be valid a valid price'
        ]
      }
    }
  }
}